import React, {useState, useEffect} from 'react'
import AllBooking from './AllBookings/AllBookings'
import InProgress from './InProgress/InProgress'
import Completed from './Completed/Completed'
import Cancel from './Cancel/Cancel'
import { useDispatch, useSelector } from 'react-redux'
import {
  getAllBooking,
  getCompletedBooking,
  getCancelledBooking,
  markAsComplete,
  markAsCancel,
  clearMessages,
  resetMark,
  getInProgressBooking
} from "../../redux/bookings/actions";
import styles from './Bookings.module.scss'
import toast from 'react-hot-toast'
import {BaseUrl} from "../../config/serverUrl";
import axiosInstance from "../../config/axiosInstance";

const Bookings = () => {
    const dispatch = useDispatch()
    const [selectedOrders,setSelectedOrders]=useState([])
    const [action,setAction]=useState('')
    const [filter, setFilter] = useState({ start_date: '', end_date: '', status: '' });
    const [filteredBookings, setFilteredBookings] = useState([]);
    const booking = useSelector(state => state.booking)
    const { loading, data, updated, error, mark } = booking;

    useEffect(() => {
        dispatch(getAllBooking(filter.start_date, filter.end_date, filter.status));
        dispatch(getCancelledBooking(filter.start_date, filter.end_date, filter.status));
        dispatch(getCompletedBooking(filter.start_date, filter.end_date, filter.status));
        dispatch(getInProgressBooking(filter.start_date, filter.end_date, filter.status));
    }, [loading])


     useEffect(() => {
       if (mark) {
         toast.success(`Successfully,Marked as ${action}`);
         setTimeout(() => {
           dispatch(resetMark());
           dispatch(getAllBooking());
           dispatch(getCompletedBooking());
           dispatch(getCancelledBooking())
           dispatch(getInProgressBooking());
         }, 2000);
       }
     }, [mark]);


    const [allBookings, setAllBookings] = useState(true)
    const [inprogress, setInprogress] = useState(false)
    const [completed, setCompleted] = useState(false)
    const [cancel, setCancel] = useState(false)

    const exportBookings = async (e) => {
        const response = await axiosInstance.get(`${BaseUrl}/admin/booking/get-all-bookings/export?start_date=${filter.start_date??''}&end_date=${filter.end_date??''}&status=${filter.status??''}`, { responseType: "blob" });
        console.log(response, 'response');
        let filename = response.headers["content-disposition"]
            .split("filename=")[1]
            .split(".")[0];
        let extension = response.headers["content-disposition"]
            .split(".")[1]
            .split(";")[0];
        const url = URL.createObjectURL(
            new Blob([response.data], {
                type: "application/vnd.ms-excel",
            })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${filename}.${extension}`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleClick = e => {

        setSelectedOrders([])
        if (e.currentTarget.dataset.id === '1') {
            setAllBookings(true)
            setInprogress(false)
            setCompleted(false)
            setCancel(false)
        } else if (e.currentTarget.dataset.id === '2') {
            setAllBookings(false)
            setInprogress(true)
            setCompleted(false)
            setCancel(false)
        } else if (e.currentTarget.dataset.id === '3') {
            setAllBookings(false)
            setInprogress(false)
            setCompleted(true)
            setCancel(false)
        } else if (e.currentTarget.dataset.id === '4') {
            setAllBookings(false)
            setInprogress(false)
            setCompleted(false)
            setCancel(true)
        }
    }

    const handleFilterSubmit = async (e) => {
        e.preventDefault();

        // Capture form data
        const formData = new FormData(e.target);
        const start_date = formData.get('start_date');
        const end_date = formData.get('end_date');
        const status = allBookings ? 'all' : inprogress ? 'inprogress' : completed ? 'completed' : cancel ? 'cancel' : '';

        // Update the filter state
        setFilter({
            start_date: start_date,
            end_date: end_date,
            status: status,
        });

        // Conditionally dispatch actions based on the status
        if (status === 'all') {
            dispatch(getAllBooking(start_date, end_date, status));
        } else if (status === 'inprogress') {
            dispatch(getInProgressBooking(start_date, end_date, status));
        } else if (status === 'completed') {
            dispatch(getCompletedBooking(start_date, end_date, status));
        } else if (status === 'cancel') {
            dispatch(getCancelledBooking(start_date, end_date, status));
        } else {
            console.error('Unknown status');
        }

    };

    let items = 144
    let title = allBookings ? 'All Booking' : inprogress ? 'In Progress' : completed ? 'Completed' : cancel ? 'Cancel' : null


    return (
      <section className="mainContainer">
        {/* TITLE */}
        <h1 className="title">{title}</h1>

        {/*** Tab Navigation ***/}

        <div className={styles.nav}>
          <div
            className={allBookings ? styles.activeNavItem : styles.navItem}
            data-id="1"
            onClick={handleClick}
          >
            <h6>All Booking</h6>
            <small>{data.all.bookings && data.all.bookings.length}</small>
          </div>

          <div
            className={inprogress ? styles.activeNavItem : styles.navItem}
            data-id="2"
            onClick={handleClick}
          >
            <h6>In Progress</h6>
            <small>
              {data.inProgress.bookings && data.inProgress.bookings.length}
            </small>
          </div>

          <div
            className={completed ? styles.activeNavItem : styles.navItem}
            data-id="3"
            onClick={handleClick}
          >
            <h6>Completed</h6>
            <small>
              {data.completed.bookings && data.completed.bookings.length}
            </small>
          </div>
          <div
            className={cancel ? styles.activeNavItem : styles.navItem}
            data-id="4"
            onClick={handleClick}
          >
            <h6>Cancel</h6>
            <small>
              {data.cancelled.bookings && data.cancelled.bookings.length}
            </small>
          </div>
        </div>

        <div
          className={'d-flex align-items-center gap-3 bg-white p-3 mb-4 rounded'}
        >
            <div className={'flex-grow-1'}>
                {allBookings && (
                    <form id={'filter-form'} className="d-flex gap-3" onSubmit={handleFilterSubmit}>
                        <div className={'d-flex gap-1 align-items-center'}>
                            <label htmlFor="start_date" className={'fs-6 fw-bold'}>Start Date</label>
                            <div className={'flex-grow-1'}>
                                <input type="date" className="form-control" id="start_date" name={'start_date'}/>
                            </div>
                        </div>
                        <div className="d-flex gap-1 align-items-center">
                            <label htmlFor="end_date" className={'fs-6 fw-bold'}>End Date</label>
                            <div className={'flex-grow-1'}>
                                <input type="date" className="form-control col-auto" id="end_date" name={'end_date'}/>
                            </div>
                        </div>
                        <div className="">
                            <button type="submit" className="btn btn-primary">Filter</button>
                        </div>
                    </form>
                )}

            </div>
            {allBookings && (
            <button
                // style={{alignSelf: "flex-end"}}
                type="button"
                class="btn btn-danger"
                onClick={exportBookings}
            >
                Export
            </button>
            )}

            <button
                disabled={!selectedOrders.length}
                // style={{alignSelf: "flex-end"}}
                type="button"
                class="btn btn-danger"
                onClick={() => {
                    setAction('canceled')
                    dispatch(markAsCancel({id: selectedOrders}))

                }


                }
            >
                Mark As Cencel
            </button>

            <button
                disabled={!selectedOrders.length}
                // style={{ alignSelf: "flex-end" }}
                type="button"
                class="btn btn-success"
                onClick={() => {
                    setAction('completed')
                    dispatch(markAsComplete({id: selectedOrders}))

                }
            
            
            }
          >
            Mark As Complete
          </button>
        </div>

        {/* CARD */}
        <div className="sectionCard">
          <div className={styles.container}>
            {allBookings ? (
              <AllBooking
                  bookings={filteredBookings}
                setSelectedOrders={setSelectedOrders}
                selectedOrders={selectedOrders}
              />
            ) : inprogress ? (
              <InProgress
                  bookings={filteredBookings}
                setSelectedOrders={setSelectedOrders}
                selectedOrders={selectedOrders}
              />
            ) : completed ? (
              <Completed bookings={filteredBookings} />
            ) : cancel ? (
              <Cancel bookings={filteredBookings} />
            ) : null}
          </div>
        </div>
      </section>
    );
}

export default Bookings